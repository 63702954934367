<template>
  <div class="church-ministry template-1">
    <header class="page-header container">
      <h1>{{ translations.tcQualifiedPersonalWitnessingTrainers }}</h1>
    </header>
  </div>
</template>

<script>
/* eslint-disable */
import { mapGetters } from 'vuex'
import { translationMixin } from '@/mixins/translationMixin'

export default {
  name: 'qualified-personal-witnessing-trainers',
  mixins: [translationMixin],
  data() {
    return {
      translations: {},
    }
  },
  async created() {
    await this.getViewTranslations()
  },
  computed: {
    ...mapGetters({
      prefCulture: 'user/userPreferredCulture',
    }),
  },
  components: {},
  methods: {},
}
</script>
